import React, { Component } from 'react';

import ImgWrapper from '../../components/UI/ImgWrapper/ImgWrapper';

import classes from './BlogPost.module.css';
import MaxB_1 from "../../assets/images/MaxB/MAXB_1.jpg";
import MaxB_kitchen from "../../assets/images/MaxB/MAXB_2.jpg";
import MaxB_cooking from "../../assets/images/MaxB/MAXB_3.jpg";
import MaxB_pot from "../../assets/images/MaxB/MAXB_4.jpg";
import MaxB_potempty from "../../assets/images/MaxB/MAXB_5.jpg";
import MaxB_dessert from "../../assets/images/MaxB/MAXB_6.jpg";
import MaxB_table from "../../assets/images/MaxB/MAXB_7.jpg";
import MaxB_closeup from "../../assets/images/MaxB/MAXB_8.jpg";
import MaxB_jars from "../../assets/images/MaxB/MAXB_10.jpg";
import MaxB_behind from "../../assets/images/MaxB/MAXB_11.jpg";
import MaxB_tattoo from "../../assets/images/MaxB/MAXB_12.jpg";
import MaxB_side from "../../assets/images/MaxB/MAXB_13.jpg";

class MaxB extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render () {
		return (
			<div className={classes.BlogPost}>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--first" src={MaxB_1} />
				<h2 className={[classes.BlogPost__Title, classes['BlogPost__Title--Rewriting']].join(' ')}>Cook Like a Grandma: Max Boonthanakit, Chef</h2>
				<p>There is a definite peculiarity to chef Max Boonthanakit’s palate. His favorite meal consists of chicken tenders, honey mustard, spinach dip, and a sundae. He considers McDonalds to be “pretty good” food. There’s a level of modesty in his taste preferences and general demeanor that downplays the brilliance in his cooking. </p>
				<p>Take the setting of our interview: we meet Max at his family’s home in Diamond Bar where his family has lived for the last 10+ years. He greets us barefoot, casually dressed, and lightly tattooed, and we say hello to his mother before we sit around the living room.</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_kitchen} />
				<p>At this time, he’s preparing to embark on a new journey – to move to Thailand to open a new restaurant under Alain Ducasse, a legend in the culinary world. The restaurant will be French and likely decadent. But, by his admission, Max hasn’t cooked French food in a professional setting, so this was not an obvious route. </p>
				<p>By age 27, Max has worked at various Los Angeles hotspots such as The Bazaar by José Andrés, ink., and Republique. His latest achievement was opening Nightshade, which will inevitably join the other names as a fixture in LA. Sprinkled in between are stints at a boba shop, a chain fish house, and a private club. There is no pattern, no calculated movements. </p>
				<p>“I’m drawn to anything delicious,” Max explains, “My parents like eating well, too. They’d take us to nice restaurants. My brother and I, at ten, would be like, ‘I want foie gras! I want beef tartare! I want the tasting menu!’” Chasing exciting flavors became an early sport for Max and still guides him today.</p>
				<p>Max’s first foray into cooking was at 13 when he spent time at an aunt’s noodle restaurant in Georgia. Later, at the family dinner table, his father smiles as he recalls how he had hoped Max would have had a terrible experience, so he wouldn’t pursue cooking. But between spending time cooking and playing video games, cooking won out. Were his parents truly supportive? He feels lucky they were very open. Coming from a creative family (his father is a storyboard artist and mother used to be in fashion), he says his parents just told him “not to half-ass it.” </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper" src={MaxB_cooking} />
				<p>Max grew up in Atlanta which had its pockets of Asian communities. But in school, Max was one of a few Asians and that led to early encounters with a pervasive but non-threatening kind of racism: “in the south, racism is just there. Everyone just ends up accepting it… The janitor used to call me ‘wonton.’ It was crazy now that I think about it.” Even with these experiences with racial insensitivity, Max displays an acute awareness of various cultures which become fodder for his work. He engages often with new cuisine and all its ingredients, methodology, and flavor accents and in the process, develops empathy with the people who grew up on it. </p>
				<p>When Max moved to Diamond Bar, California in his early teens, he realized being an Asian in a predominantly Asian city wasn’t a shoo-in for inclusion. There, he faced norms that went counter to what he familiarized himself with in Atlanta – cool was defined by your SAT score not how many drinks you could take down at a party. “I was never in the crowd, but I was always outside which I think is good, eventually, because you end up taking in more,” Max acknowledges. Perhaps it is this feeling of being an outsider that allows him the freedom to walk the planes of culture in search for innovative flavors. Rather than look externally for validation, Max embraces cooking with soul. </p>
				<p><b>Refractor</b></p>
				<p><i>Max</i></p>
				<p><b>How are you putting emotion or soul into your food?</b></p>
				<p><i>I like to capture moments in time, especially with dessert, like the [coconut mousse at Nightshade]. It reminds me of a vacation on a cruise ship, like having a piña colada. I like to capture really nice moments in time and try to create a dish out of that. The almond one with the orange bowls: that's like after you finish a really good dim sum meal and you get the almond tofu and the oranges. It captures that little moment.</i></p>
				<p><i>Yesterday, [my girlfriend] Wendy and I, we were eating shaved ice, and we were like, “this is so fucking good!” I want to be able to capture this feeling of eating brown sugar, Taiwanese shaved ice, and condensed milk. So then it hit me. I got an idea for a dish that I'm probably going to do in Thailand now. It's a brown sugar sorbet with a reduced milk cream on it, and that's it. Super simple, but it can't be an exact replica of what you're doing. You can’t put boba in. If you have boba in that dish, it's going to ruin it. Even though the one I'm eating right now has boba and pudding. It's amazing, but if I put boba in my dish, it totally kills the point for me.</i></p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_pot} />
				<p><b>Is it because you’re trying to capture the essence, not trying to be literal about it?</b></p>
				<p><i>Yeah, I'm just trying to capture that feeling, and I want people to think about it like, “oh, this reminds me of something, and that makes me happy, but I don't really know what.”</i></p>
				<p><i>Not everyone likes the desserts [at Nightshade]. I've seen a lot of reviews where they didn't like the coconut dessert, or they didn't understand the almond one. And I understand that. I don't really get upset cause there's dishes that I don't really care for, and ones I like that other people don't. I try to give people enough options.</i></p>
				<p><i>The technique I use the most when I cook or create something is to put myself in someone else’s shoes, usually it’s a grandma.</i></p>
				<p><b>Why grandma?</b></p>
				<p><i>Cause they cook with the most soul. They’re not going to be precise. There should be a little bit of this and a little bit of that. It ends up just tasting good.</i></p>
				<p><i>I’ll be like, “I’m a Thai grandma, but I was on a boat. It crashed, and we got stuck onshore in Mexico. I have these ingredients now. How am I going to create something as soulful here with these ingredients?</i></p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_potempty} />
				<p>For Max, the dishes he creates mimic life and often refer to memories and experiences. He recognizes the fluidity of memory. When describing the inspiration for the coconut dessert, he thought about “cruises, ocean breeze, piña coladas, and sand.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_dessert} />
				<p>“For me, the vessel had to be rough to represent sand. It helps that it looks like a coconut. When you look at the dessert, you're like, ‘why is the color green? It's really weird.’ It only makes sense to me cause my uncle used to always sing that song about putting the lime in the coconut. It always stuck with me.”</p>
				<p>He also doesn’t try to be precise about his work. One example he gives is how the guava cheese dessert he developed at Nightshade is layered with a coat of marble-looking white chocolate. It’s a play on the marble tables at the restaurant. But while his cook had once labored with trying to make the marble look perfect, Max demurred with the observation that no marble looks the same. To replicate completely is a futile exercise in his mind. </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_table} />
				<p>He makes parallels to art and paintings. There was a time when art held a more utilitarian purpose, visually interpreting scenes, objects, and people to varying degrees. But with the invention of photography, artists then faced the reality that the camera served this function better. “Now that you’ve reached that point of [visual] mastery, there’s no soul anymore,” Max claims. Like the progression of modern art, Max cares much more about feeling, human connection, and concoctions of the subconscious. </p>
				<p>It’s no surprise he ultimately creates dishes that he thinks his friends would enjoy. His “basic palate” doesn’t allow him to make objective decisions about whether a dish is truly complete. Rather, he’s encouraged to receive input from others and study their reactions to his food: “cause if people were to eat like me, they’d get sick... I like asking and studying what other people eat and what other people like. I love that moment when someone eats something and their eyebrows raise up, and a lot of people don’t even notice they do that.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_closeup} />
				<p>Opening Nightshade at 27 is an incredible accomplishment. Even chefs twice his age may never get the chance to pull off something similar. The way he speaks about the experience of getting started is so nonchalant, it’s as if he’d done it before. The obstacles that would have threatened other chefs — delays in opening, obtaining licenses, adhering to fire codes — don’t appear to have made a dent in his psyche. And yet, Max swiftly moves onto his next challenge with ever-increasing ambition. </p>
				<p>When Alain Ducasse came calling, Max found the opportunity to be a timely way to shake things up. A self-proclaimed lover of change, Max was initially deterred but excited by the fact that he hadn’t ever done French food. On his approach with the new restaurant, Max explains:</p>
				<p>“You have to think of a dish in a French mentality, which is hard to explain. This goes back to putting yourself into someone's shoes. Like I'll put myself into a French grandma’s shoes and be like, ‘Now I'm just stuck in Thailand. How am I going to create this initially French thing that tastes special, in Thailand, using different ingredients from Asia. It's going to be fun, but scary at the same time.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_jars} />
				<p>Although Max is half-Thai (and half-Taiwanese), going back to Thailand didn’t spark any notions of identity-searching. He mentions he is drawn to the place and it helps that he has family there, but as far as ethnic-connection goes, “I feel not Thai.” The journey is less pilgrimage to the motherland, and more of his expansion of culinary vocabulary. </p>
				<p>As we spend more time speaking about his history, it’s shocking how many different types of cuisines he has learned to cook over the years and how he’s still willing to learn more. At a certain point, wouldn’t he want to settle down and master one? And doing Asian fusion at Nightshade, one would think he would’ve continued to focus on Asian cuisine. </p>
				<p>With constant experimentation and blending of experiences, memories, cultures, Max is intent on uncovering new, genre-bending dishes. Given the tight connection between food and culture, we wondered if Max even believed there was a place for authentic cuisines. He's wired to subvert the “traditional” yet still maintains respect for authenticity. There's a fine line he treads. </p>
				<p><b>What even makes Thai food Thai, anyways? Nowadays everything's blended together. Orange chicken in the U.S. is not really Chinese.</b></p>
				<p><i>Thai food tastes Thai just because the ingredients they use are Thai. The fun thing about cooking is substituting stuff. That's what I love doing. Say you're making the larb dish. It's pork, toasted rice, fish sauce, lime, shallots. What if you switched all that shit around? Instead of toasting rice, you toasted buckwheat. Instead of lime, use passion fruit. Instead of fish sauce, you use something crazy.</i></p>
				<p><b>It's kind of interesting, though. When chefs are doing these innovative dishes, it's a bit heretical because there's a traditional way to make a certain kind of dish that's happened for centuries, and now you're smashing it. How do you think about that?</b></p>
				<p><i>But then when you think about it, there's those dishes, but there's also good versions of those dishes where it's not so traditional. To you, it might taste traditional. In Korea, there's probably someone who makes the best kimchi, but everyone makes kimchi. There's going to be someone who has one that's a little better because maybe, instead of oysters, they put anchovies it. There's definitely leeway with anything, even traditional cooking.</i></p>
				<p><b>So does that mean you don’t believe in authentic food?</b></p>
				<p><i>I do, but I think authenticity comes from more of the thought and the feeling instead of the ingredients themselves.</i></p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper" src={MaxB_behind} />
				<p>What seems radical today become the traditions of tomorrow. Why let history get in the way of new flavors? We ponder the banh mi which originally was rooted in French ingredients. At a certain point in Vietnam’s history, the French baguette sandwich was replaced with ingredients endemic to Vietnam – substituting ham, butter, and cheese with pork sausage, cilantro, cucumber, pickled carrots, and pickled daikon. Now the banh mi is tightly associated with Vietnam and its culture.   </p>
				<p>To be able to reinvent is to first imagine how something can be. Max believes his greatest strength is his ability to be open while he thinks the majority of chefs are close-minded. He elaborates, “They want to say they’re open-minded, but they’ll say, ‘That’s not the right technique.’” This mentality may help explain why he could leave a situation that was good and that was working. </p>
				<p>What drives Max? At first, he responds that he “really likes material things. I want a Range Rover... I want to be able to travel. I want to have nice things, so I’d better be good at the thing I'm doing.” Prodding a bit further he reveals, “I have this really weird vision for myself. I want to get to the point where I'm 50 years old, and I'm a legend. I don’t know how to get there, but that’s what I envision. When they talk about me cooking, at 50 years old, I'm like this culinary god. I know that’s where I want to be. I'm nowhere close, but that’s what is pushing me.” </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_tattoo} />
				<p>There's an incredible focus on food and food alone. Throughout our conversation, despite our best efforts to analyze and dissect his motivations, Max avoids over-intellectualizing his approach. "At the end of the day, it’s proving to yourself you can do it. Because if I'm not going to cure cancer, I might as well get really good at the thing that I do and let the people that are curing cancer have really good food.” </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={MaxB_side} />
			</div>
		);
	}
}

export default MaxB;