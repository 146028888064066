import React from 'react';

import classes from './ImgWrapper.module.css';

const imgWrapper = (props) => (
	<div className={[classes.ImgWrapper, classes[props.extraClass]].join(' ')}>
		<img className={classes.Img} src={props.src} alt="" />
	</div>
);

export default imgWrapper;