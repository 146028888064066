import React, { Component } from 'react';
import { withRouter } from 'react-router';
import * as _ from 'underscore';
// import ReactGA from 'react-ga';

import PrimaryNav from '../../components/Navigation/PrimaryNav/PrimaryNav';
import BtnToTop from '../../components/UI/BtnToTop/BtnToTop';
import Footer from "../../components/Footer/Footer";

import classes from './Layout.module.css';

class Layout extends Component {
	constructor (props) {
		super(props);
		this.state = {
			showSecondaryNav: false,
			showScrollTopBtn: false,
			backGround: ""
		};
	}
	
	componentDidMount() {
		// const trackingId = "G-EHTN0XZKBH";
		// ReactGA.initialize(trackingId);
		this.unlisten = this.props.history.listen(location => {
			// ReactGA.set({ page: location.pathname }); // Update the user's current page
			// ReactGA.pageview(location.pathname); // Record a pageview for the given page
			if (this.state.showSecondaryNav) this.setState({showSecondaryNav: false})
		});
		window.addEventListener('scroll', this.handleScroll, true);
	}
	
	componentWillUnmount() {
		this.unlisten();
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	hideScrollToTopBtnTimer = () => {
		clearTimeout(this.hideScrollToTopBtnTimerInstance);
		this.hideScrollToTopBtnTimerInstance = setTimeout(() => {
			this.setState({showScrollTopBtn: false});
			this.hideScrollToTopBtnTimerInstance = null;
		}, 3000)
	};
	
	handleScroll = _.throttle(() => {
		if (window.pageYOffset > 300) {
			if (!this.state.showScrollTopBtn) this.setState({showScrollTopBtn: true});
			this.hideScrollToTopBtnTimer();
		} else {
			if (this.state.showScrollTopBtn) {
				clearTimeout(this.hideScrollToTopBtnTimerInstance);
				this.setState({showScrollTopBtn: false});
			}
		}
	}, 300);
	
	setMainBackground = (className) => {
		const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		if (viewportWidth > 768 && this.state.backGround !== className) {
			this.setState({backGround: className});
		} else if (this.state.background !== "") {
			this.setState({backGround: ""});
		}
	};
	
	hideMainBackground = () => {
		if (this.state.backGround !== "") {
			this.setState({backGround: ""});
		}
	};
	
	closeSecondaryNav = () => {
		this.setState({showSecondaryNav: false});
	};
	
	showSecondaryNav = () => {
		this.setState({showSecondaryNav: true});
	};
	
	scrollToTop = () => {
		window.scrollTo(0, 0)
	};
	
	render () {
		return (
			<main className={[classes.Content, classes[this.state.backGround]].join(" ")}>
				<PrimaryNav
					showSecondaryNav={this.state.showSecondaryNav}
					closeBtnClickedHandler={this.closeSecondaryNav}
					showBtnClickedHandler={this.showSecondaryNav} />
					{this.props.children(this.setMainBackground, this.hideMainBackground)}
				<BtnToTop showScrollTopBtn={this.state.showScrollTopBtn} scrollToTop={this.scrollToTop} />
				<Footer />
			</main>
		);
	}
}

export default withRouter(Layout);