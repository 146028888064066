import React from 'react';
import { Link } from 'react-router-dom';

import classes from './SecondaryNav.module.css';
import classNames from 'classnames/bind';
import { ReactComponent as X } from '../../../assets/images/X.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/ArrowRight.svg';

let cx = classNames.bind(classes);

const secondaryNav = (props) => {
	let className = cx({
		SecondaryNav: true,
		'SecondaryNav--show': props.showSecondaryNav
	});
	
	return (
		<div className={className}>
			<div className={classes.SecondaryNavLinkContainer}>
				<Link className={classes.SecondaryNavLink} to="/stories"><ArrowRight className={classes.SecondaryNavLinkIcon} /><h1>Stories</h1></Link>
				<Link className={classes.SecondaryNavLink} to="/about"><ArrowRight className={classes.SecondaryNavLinkIcon} /><h1>About</h1></Link>
				<Link className={classes.SecondaryNavLink} to="/contact"><ArrowRight className={classes.SecondaryNavLinkIcon} /><h1>Contact</h1></Link>
				<button className={classes.SecondaryNavCloseBtn} onClick={props.closeBtnClickedHandler}><X className={classes.SecondaryNavCloseIcon} /></button>
			</div>
		</div>
	);
};

export default secondaryNav;