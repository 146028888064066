import React from 'react';

import classes from './Footer.module.css';
import { ReactComponent as ArrowRight } from '../../assets/images/ArrowRight.svg';

const footer = (props) => (
	<footer className={classes.Footer}>
		<form className={classes.FooterSignUpForm} action="https://formspree.io/myrldgex" method="POST">
			<label className={classes.FooterSignUpLabel} htmlFor={classes.FooterSignUpInput}>Sign Up For Our
				Newsletter</label>
			<div className={classes.FooterSignUpInputContainer}>
				<input className={classes.FooterSignUpInput} type="email" name="replyto" placeholder="Email Address" required />
				<input type="hidden" name="subject" value="Sign Up for Refractor Newsletter" required />
				<button className={classes.FooterSignUpSubmit} type="submit"><ArrowRight className={classes.FooterSignUpSubmitIcon} /></button>
			</div>
		</form>
		<div className={classes.FooterTextContainer}>
			<h4>A mosaic of Asian-American creativity.</h4>
			<h4>
				<span className={classes.FooterTextInstagram}><a href="https://www.instagram.com/refractor.media/">instagram</a></span>
				<span className={classes.FooterTextEmail}>hi@refractor.media</span>
				<span>©2019</span>
			</h4>
		</div>
	</footer>
);

export default footer