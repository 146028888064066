import React, { Component } from 'react';

import StoryLink from './StoryLink/StoryLink';

import ObservationsDesk from '../../assets/images/Observations_desk.jpg';
import Run_river_bg from '../../assets/images/RunRiver/RRN_1.jpg';
import MaxBCover from '../../assets/images/MaxB/MAXB_1_alt.jpg';
import classes from './Stories.module.css';

class Stories extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render () {
		return (
			<div className={classes.Stories}>
				<StoryLink
					img_src={MaxBCover}
					header="Cook Like a Grandma: Max Boonthanakit, Chef"
					description="Uncovering hidden flavors from within"
					path="/max_boonthanakit_cook_like_a_grandma" />
				<StoryLink
					img_src={Run_river_bg}
					header="Learning to Dance: Run River North"
					description="How to find joy in your current reality"
					path="/run_river_north_learning_to_dance" />
				<StoryLink
					img_src={ObservationsDesk}
					header="Rewriting the Past: Adrian Kay Wong, Artist"
					description="Examining how personal history holds us back in the present"
					path="/adrian_kay_wong_rewriting_the_past" />
			</div>
		);
	}
}

export default Stories;