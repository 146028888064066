import React, { Component } from 'react';

import classes from './About.module.css';

class About extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render () {
		return (
			<div className={classes.About}>
				<div className={classes['About__Flex-container--first']}>
					<h3>Refractor seeks to discover and advance Asian-American creativity through the lens of the individual.</h3>
				</div>
				<div className={classes['About__Flex-container--second']}>
					<p>We believe there is an opportunity to introduce a new perspective that progresses the standard narratives confronting Asian-Americans by telling the stories of individuals who have vigorously chosen to pursue their creative interests.</p>
					<p>When we reflect on our own upbringing, we acknowledge how the mixture of Asian tradition, being minorities, and the immigrant mentality of our parents influence whether we feel empowered to exercise our creative freedom. Can creativity still thrive from these conditions? This is a fundamental topic Refractor looks to clarify.</p>
					<p>Through these stories, we hope to inspire those who may just be starting to understand their own capabilities and bring solidarity to those who are already on that journey. A natural outcome is that our readers may be more inclined to support the individuals whose stories are told. We define success by how we are pushing thinking and the creative ecosystem in the context of Asian-American culture.</p>
				</div>
			</div>
		);
	}
}

export default About;