import React, { Component } from 'react';

import classes from './Contact.module.css'
import { ReactComponent as ArrowRight } from '../../assets/images/ArrowRight.svg';

class Contact extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render () {
		return (
			<div className={classes.Contact}>
				<div className={classes.ContactHeader}>
					<h3>Let’s start a dialogue.</h3>
					<h3 className={classes.ContactHeaderLight}>Tell us how you felt about the last article you read. Introduce us to new perspectives and people. Inquire about making content contributions.</h3>
				</div>
				<div className={classes.ContactContent}>
					<p>Refractor operates under a set of values that include, above all else, discourse, partnership, and progress.</p>
					<p>We’re committed to ideas that compel us to consider change.</p>
					<form className={classes.ContactForm} action="https://formspree.io/myrldgex" method="POST">
						<input className={classes.ContactForm__Inputs} type="text" name="name" placeholder="Name" required />
						<input className={classes.ContactForm__Inputs} type="email" name="replyto" placeholder="Email" required />
						<input className={classes.ContactForm__Inputs} type="text" name="subject" placeholder="Subject" required />
						<textarea className={[classes.ContactForm__Inputs, classes.ContactForm__Textarea].join(" ")} name="message" placeholder="Message"/>
						<button className={classes.ContactForm__Submit} type="submit">
							<p>Submit</p>
							<ArrowRight className={classes.ContactForm__SubmitIcon} />
						</button>
					</form>
				</div>
			</div>
		);
	}
}

export default Contact;