import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ImgWrapper from '../../components/UI/ImgWrapper/ImgWrapper';

import ObservationsBg from '../../assets/images/Observations_bg.jpg';
import RunRiverCover from '../../assets/images/RunRiver/RRN_1.jpg';
import MaxBCover from '../../assets/images/MaxB/MAXB_1_alt.jpg';
import { ReactComponent as Circle } from '../../assets/images/Circle.svg';
import classes from './Home.module.css';

class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	componentWillUnmount() {
		this.props.hideMainBackground();
	}
	
	render () {
		return (
			<div className={classes.Home}>
				<Link
					onMouseOut={this.props.hideMainBackground}
					onMouseOver={() => this.props.setMainBackground("MaxB")}
					className={classes.HomeLink}
					to="max_boonthanakit_cook_like_a_grandma">
					<ImgWrapper extraClass="HomeLinkImgWrapper" src={MaxBCover} />
					<div className={classes.HomeLinkHeader}>
						<Circle className={classes.HomeLinkHeaderCircle} />
						<h2 className={[classes.HomeLinkHeaderText, classes['HomeLinkHeaderText--Rewriting']].join(' ')}>Cook Like a Grandma: Max Boonthanakit, Chef</h2>
					</div>
					<h3 className={classes.HomeLinkSubheader}>Uncovering hidden flavors from within</h3>
				</Link>
				<Link
					onMouseOut={this.props.hideMainBackground}
					onMouseOver={() => this.props.setMainBackground("RunRiver")}
					className={classes.HomeLink}
					to="run_river_north_learning_to_dance">
					<ImgWrapper extraClass="HomeLinkImgWrapper" src={RunRiverCover} />
					<div className={classes.HomeLinkHeader}>
						<Circle className={classes.HomeLinkHeaderCircle} />
						<h2 className={[classes.HomeLinkHeaderText, classes['HomeLinkHeaderText--Rewriting']].join(' ')}>Learning to Dance: Run River North</h2>
					</div>
					<h3 className={classes.HomeLinkSubheader}>How to find joy in your current reality</h3>
				</Link>
				<Link
					onMouseOut={this.props.hideMainBackground}
					onMouseOver={() => this.props.setMainBackground("Observations")}
					className={classes.HomeLink}
					to="adrian_kay_wong_rewriting_the_past">
					<ImgWrapper extraClass="HomeLinkImgWrapper" src={ObservationsBg} />
					<div className={classes.HomeLinkHeader}>
						<Circle className={classes.HomeLinkHeaderCircle} />
						<h2 className={[classes.HomeLinkHeaderText, classes['HomeLinkHeaderText--Rewriting']].join(' ')}>Rewriting the Past: Adrian Kay Wong, Artist</h2>
					</div>
					<h3 className={classes.HomeLinkSubheader}>Examining how personal history holds us back in the present</h3>
				</Link>
			</div>
		);
	};
}

export default Home;