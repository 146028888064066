import React, { Component } from 'react';

import ImgWrapper from '../../components/UI/ImgWrapper/ImgWrapper';

import classes from './BlogPost.module.css';
import RunRiverCover from "../../assets/images/RunRiver/RRN_1.jpg";
import RunRiverChairs from "../../assets/images/RunRiver/RRN_2.jpg";
import RunRiverOld from "../../assets/images/RunRiver/RRN_3.jpg";
import RunRiverTalk from "../../assets/images/RunRiver/RRN_4.jpg";
import RunRiverAlex from "../../assets/images/RunRiver/RRN_5.jpg";
import RunRiverHands from "../../assets/images/RunRiver/RRN_6.jpg";
import RunRiverDaniel from "../../assets/images/RunRiver/RRN_7.jpg";
import RunRiverSally from "../../assets/images/RunRiver/RRN_8.jpg";
import RunRiverLaugh from "../../assets/images/RunRiver/RRN_9.jpg";


class RunRiver extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render () {
		return (
			<div className={classes.BlogPost}>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--first" src={RunRiverCover} />
				<h2 className={[classes.BlogPost__Title, classes['BlogPost__Title--Rewriting']].join(' ')}>Learning to Dance: Run River North</h2>
				<p>We're not quite sure how to describe 10408 Balboa Blvd and neither can Google Maps. Google’s red pin drops on a large-ish area next to the Valley Academy of Arts and Sciences and Asahi Sushi. The descriptor is literal: 10408 Balboa Blvd.</p>
				<p>The building’s sign says Valley Korean United Methodist Church. But walking around the complex we see children’s classrooms, senior citizens vigorously playing ping pong, and smoke drifting into the sky from the parking lot.</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={RunRiverChairs} />
				<p>We start near the backlot and are guided up the stairs to a room with a coffee bar where we find Sally Kang (keys/vocals) and Daniel Chae (guitar/vocals) of the LA-based band Run River North. Well-dressed and put together, they’re surrounded by a couple acquaintances. Others walk in and out to say hello. Alex Hwang, the front man, arrives not long after to the embrace of those in the room.</p>
				<p>Without a full-time bassist or drums or, in a past iteration, a violinist, the trio has just come back from a trip to SXSW where they spent nearly a week playing multiple shows and making a run with the press. The band formed in 2011, under their original moniker “Monsters Calling Home,” with six Asian American band members. Two albums and 400 performances later, Run River North would end up going on tour for the first time without three of their original bandmates.</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={RunRiverOld} />
				<p>After exchanging introductions, we head down to the “ping pong room,” an empty room with sunlight piercing through old vertical blinds and mirrors covering one wall. We fiddle around with chairs until we settle into an arrangement as if we're about to perform an intervention on the recorder. The interview setting is DIY – reminiscent of a scene from one of their music videos like “Fight to Keep,” the video that enabled the band to play on Jimmy Kimmel Live in 2012. Their Kimmel appearance is widely known among fans, yet less has been written about how their resourcefulness and vulnerability permeates the band’s entire approach. </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper" src={RunRiverTalk} />
				<p>With Kimmel, the band caught fire early. When the band first formed, they committed to making twelve songs in the first year with the goal of getting signed and playing in established venues like Troubadour in LA. “Fight to Keep” just happened to be their second song. But the steps that got them to that point were not clear-cut; it was not obvious they would find such early interest.</p>
				<p>The six original bandmates did not know each other prior, but all grew up in the San Fernando Valley. The first time Alex crossed paths with Daniel was through the Valley Korean Youth Orchestra where Alex played saxophone. Alex recalls, “There's a solo violinist that we don't know of. And then halfway through, Daniel comes in, and he's the soloist. The conductor's like, ‘Okay, listen up. This is Daniel. You guys all play for him.’” </p>
				<p>Although Daniel was musically talented, the key spark he later provided was the idea that they could be in a band together making music they wanted to play. Alex watched Daniel’s progression from being in his own “power worship band” to deciding he no longer wanted to play worship music. Instead Daniel’s band shifted to playing songs that sounded more like Jimmy Eat World than Hillsong. Excited after listening to them perform, Alex says, “I wanted to share their music to other people and be like, ‘Hey, this is a band. They happened to be Korean-American, but that had nothing to do with how good they were.’ That plants a seed in my mind that it's possible, and these guys are probably going to be part of it somehow.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={RunRiverAlex} />
				<p>After playing guitar at coffee shops and doing open mics for several years, Alex decided to try to win a talent competition hosted by Kollaboration, an annual showcase for aspiring Korean-American entertainers. In doing so, he recruited the band and called the group Monsters Calling Home. Already, Alex was incorporating his emerging views on growing up Asian-American into the band’s representation. The name Monsters Calling Home directly addressed what he was hearing from other people about their parents and the struggles of being in an immigrant family. He knew it was relevant because he experienced it with his father as well. “It felt like there was a lot of pain and a lot of drama that it looked like if it wasn't addressed it was going to come up in the way we raise our kids or the way we have relationships with other people,” he remarks. What he saw was deeper than surface-level pain: more problematic than familial issues was that his generation could not adequately express their angst and felt ashamed to tell their stories. He was concerned that his peers would eventually make the same mistakes as their parents without even being aware of it. </p>
				<h3 className={classes['BlogPost__Pull-Quotes']}>“How do you find hope? How do you dance? How do you celebrate in the midst of all of that?” </h3>
				<p>“Just because you got hit by your parents and you don't hit your kids doesn't mean you're doing something right and they were doing something wrong. It's a lot more subtle than that,” Alex clarifies, “and I think Monsters Calling Home is definitely a direct reference to my parents. But it was also just a reflection on, ‘Hey, what am I? Am I going to be a different kind of monster, and can I be okay with that?’” </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={RunRiverHands} />
				<p>Monsters Calling Home performed at the Nokia Theater for Kollaboration among other musical acts and entertainers. Though they believed they had a good chance of winning, they shockingly lost out to the “Yo-yo Guy.” Alex recounts, “what happened was all the judges had a favorite and everyone's second was the Yo-yo Guy. [When] the points came out, the Yo-yo Guy had the most points. And even the Yo-yo Guy was… everyone was confused.” Although their initial motivation to win the Kollaboration competition was denied, the band was surprised by the response that came after the show.</p>
				<p>Endorsements from Angry Asian Man, Phil Yu, and Buzz Bands LA music blogger, Kevin Bronson, encouraged the band to keep going. For Alex, the response was enough for him to quit his job at CAA to see how far they could get in a year. Daniel notes, “All of us just… fell into it, but it took a lot of sacrifice on [Alex’s] part.” Building on that buzz, in 2012 they played at USC Springfest alongside mainstream names like Wolfgang Gartner, Macklemore, and Anberlin. The band actually performed acapella as they didn’t have any gear but because of this, was asked by Anberlin to join them on their acoustic tour. At that time, John Chong (original drummer), Joe Chun (original bassist), Daniel and Alex were in their early-to-mid 20s; Sally was 19; Jennifer Rim (original violinist) had just graduated high school. </p>
				<p>Were they hesitant about committing to such a big endeavor? Between making music, practicing, and going on tour, it’s hard to imagine there was time left for much else. Alex and Daniel provide conflicting answers. Alex then corrects himself saying there is still some hesitance today, but back then they were getting so many compelling opportunities it was hard to say no. “I think this is a very important question,” Alex starts, “the fact that you're asking about our parents. That's an Asian-American question. If we were white, I don't see you asking that question.” It triggers a discussion about where this dynamic comes from. </p>
				<p>The natural response is that immigrant parents, although they took risks emigrating to the U.S., prefer the stable and safe path. They shirk uncertainty, perhaps because it was too painful for them as they were finding their own way. There’s an embedded pragmatism especially for those who did not fully realize their ambitions. The concern is whether this perspective discourages creative souls from pursuing artistic paths. </p>
				<h3 className={classes['BlogPost__Pull-Quotes']}>“Am I going to be a different kind of monster, and can I be okay with that?” </h3>
				<p>Alex’s self-confidence guided him in conversations with his parents to be bolder about his stance. He found ways to become financially independent to eliminate the money question. Those factors allowed him and John Chong to set the example for the younger bandmates, to show that it was possible. “It took a lot of fights… lots of arguing matches and storming out the door,” Daniel recalls, “And my dad literally saying, ‘leave this house.’ But then him not meaning it. That's what it took to get our points across. Looking back, as dramatic as it sounds, that was the only vehicle for clear communication.” We wonder how many hopefuls never made it past this stage of convincing their parents to pursue the undefined path. </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper" src={RunRiverDaniel} />
				<p>We had been curious about how the Asian image and background of the bandmates would affect the audiences listening or watching their performances. Does identity and image play a part in whether others could relate to the artist? The band’s first album entitled “Run River North” invoked American folk sounds with familiar, universal narratives – “that sense of wanting to create a home and then not doing a perfect job of it.” Alex argues this combination was not limiting by race but does acknowledge there was an initial barrier for non-Asians to understand the group. Daniel describes trying to win the room every night to “stunned” audiences who couldn’t believe what they were seeing on stage. He quips, “Even now some of our biggest fans are from the south, like in Alabama or in Arkansas... You would think they would be the most prejudice. But I think when there's a real connection in that room, they're still our biggest fans.” </p>
				<p>When we get past the superficial and connect on shared human experiences, does race and ethnicity have any further relevance in the relationship between artist and audience? Alex’s incredulous reaction to the image question makes us think this isn’t a subject matter Run River North confronts any longer. In fact, we get the sense that while he embraces the band’s identity, he does not want it to overtake what the band stands for. In other words: Hey, this is a band. They happen to be Korean-American, but that has nothing to do with how good they are. Identity may have been a core topic to address early on, but the band continues to move forward. </p>
				<h3 className={classes['BlogPost__Pull-Quotes']}>“That's an Asian-American question. If we were white, I don't see you asking that question.” </h3>	
				<p>For their second album, the band bet that a change in sound and songwriting approach would help them reach more listeners. “What changed was, we didn't catapult up like the way Fleet Foxes, Head and the Heart, [Mumford & Sons] did with our songs. We had to change, or we were going to either adapt or die,” Alex reasons. To continue making music as a career, the band decided they needed to reach larger audiences which required a level of “sacrifice and compromise.” More of the band got involved in the songwriting process. They evolved the sound to progressive rock overtures in the vein of The Killers, Arctic Monkeys, and The Strokes. It was what Alex had been striving for early on, but back then he didn’t know “how to marry the two: the folk songs that [he] wanted to sing with the bravado and the pop rock sensibility.” In the next EP, they will continue to advance the sound, augmenting it with more digital effects. </p>
				<p>The more significant change they will make, however, is to observe less and experience more. Their first two albums tried to make sense of situations and circumstances. But after going through a long and painful rift within the band following the first tour to eventually losing three bandmates for good, Sally, Daniel, and Alex now seek joy. “How do you find joy after the aftermath and what does that look like? How do you find hope? How do you dance? How do you celebrate in the midst of all of that?” Alex asks. They’re searching for solutions to discover joy beyond embracing the escapism of the day. Even after playing sets without a drummer or a bassist, having close to zero in the band’s bank account, and wishing just to make it out alive through the next tour, Run River North remains optimistic. “I think joy is holding your reality, which is probably broken,” Alex answers, “and still being happy and finding hope in it.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={RunRiverSally} />
				<p>After the interview, we find out 10408 Balboa Blvd is also a barbeque joint. We share pork belly over rice provided by Daniel’s brother who smoked the meat onsite in the parking lot. It is quietly enjoyed by us and an elderly white couple. </p>
				<p>Perhaps to understand this place is another way to understand the band. The place is more than the church that brought the band together and supported them through their growth. There is a sense of grace that extends beyond spirituality and realizes the practicality necessary for the struggles in life. It is a place where compassion is color-blind. This ethos remains the foundation for Run River North. The bandmates recognize they’re fighting for something greater than themselves and find meaning in their challenges. As they venture through their next phase as a trio, they’ll get to see if “the ground of Run River North is still fertile.”  </p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={RunRiverLaugh} />
			</div>
		);
	}
}

export default RunRiver;