import React, { Component } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import About from './containers/About/About';
import Stories from './containers/Stories/Stories';
import Contact from './containers/Contact/Contact';
import Observations from './containers/BlogPost/Observations';
import RunRiver from './containers/BlogPost/RunRiver'
import MaxB from './containers/BlogPost/MaxB'

class App extends Component {
	render () {
		return (
			<BrowserRouter>
				<Layout>
					{(setMainBackground, hideMainBackground)  => (
						<Switch>
							<Route path="/" exact render={(props) => <Home {...props} hideMainBackground={hideMainBackground} setMainBackground={setMainBackground} />} />
							<Route path="/about" component={About} />
							<Route path="/contact" component={Contact} />
							<Route path="/stories" component={Stories} />
							<Route path="/run_river_north_learning_to_dance" component={RunRiver}/>
							<Route path="/max_boonthanakit_cook_like_a_grandma" component={MaxB}/>
							<Route path="/adrian_kay_wong_rewriting_the_past" component={Observations}/>
							<Redirect to="/" />
						</Switch>
					)}
				</Layout>
			</BrowserRouter>
		);
	}
}

export default App;