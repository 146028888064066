import React, { Component } from 'react';

import ImgWrapper from '../../components/UI/ImgWrapper/ImgWrapper';

import ObservationsBg from "../../assets/images/Observations_bg.jpg";
import ObservationsRoom from "../../assets/images/Observations_room.jpg";
import ObservationsDesk from "../../assets/images/Observations_desk.jpg";
import ObservationsWall from "../../assets/images/Observations_wall.jpg";
import ObservationsBack from "../../assets/images/Observations_back.jpg";
import classes from './BlogPost.module.css';

class Observations extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render () {
		return (
			<div className={classes.BlogPost}>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--first" src={ObservationsBg} />
				<h2 className={[classes.BlogPost__Title, classes['BlogPost__Title--Rewriting']].join(' ')}>Rewriting the Past: Adrian Kay Wong, Artist</h2>
				<p>Pleasanton. A sunny town in California best known for its good public schools, strip mall, and summer county fair. It’s a nice place to grow up. It’s got plenty of greenery and well-mannered people. Nothing particularly eventful happens there. It's where artist and painter Adrian Kay Wong grew up. We were familiar with Pleasanton and understood that the place didn’t birth artists, especially of Asian background.</p>
				<p>We visited him in his home studio in Lincoln Heights on a rainy Saturday morning. Prior to the interview, we joked about what it was like to be the only Asian kid in elementary school. Pleasanton may have been a pleasant place to live, but back then it wasn’t so diverse. And if your parents were immigrants and foreign to American culture, you faced dramatic differences in perspectives and norms at home and outside. You either conformed to the majority or risked being outcast, constantly picking sides to maintain the balance between youthful desire and familial customs.</p>
				<p>Even in happy places there is conflict that is less obvious, more below-the-surface. Adrian mentioned the struggles his parents went through trying to care for him and his twin brother. Trying to empathize with his father, he said, “My parents were only planning for one kid, so when you have a twin, that $30,000 you need becomes $60,000. Even though we lived in Pleasanton, where everything's really nice, I'm pretty sure he was stressing. That's probably why he is the way he is.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={ObservationsRoom} />
				<p>He described his father as cold, analytical, and withdrawn. Someone who didn’t communicate much or provide emotional support. Adrian said he had a strained relationship with his parents, yet he found himself going back to the themes of family and growing up, time and time again.</p>
				<p>In high school, it didn’t seem like Adrian was interested in art as a career. He talked more about being into sports than painting. He didn’t start painting until he got to high school, and by his account, only kept at it because he would get easy A’s. Without knowing much about the art world or considering art as a career, he decided to opt for it anyway.</p>
				<p>“It wasn’t until a month before applying to college that I actually knew anything. And I was like, ‘Oh shit. There're art schools out there that people go to, to learn about art?’” He applied to the couple art schools he remembered off-the-top of his head. Even after getting accepted into respected art schools, he still wrestled with attending UC San Diego to study statistical economics.</p>
				<p>Ultimately, his desire to leave Pleasanton and his parents forced him out of California and to the School of the Arts in Chicago. Adrian recalled the shock of realizing how far behind he was when he first got to college: “When I got to school it was like, ‘Fuck! This is nothing like what I was doing! What the fuck am I doing here?’ The very first week I had a critique, and the professor told me, ‘No one cares about your stuff.’”</p>
				<p>In high school art class, curriculum was taught as more of a trade or craft. Imagination didn’t extend beyond technical know-how. The environment didn’t promote creative endeavor beyond hobby. Further, taking the plunge and going to art school meant that he was on his own. He had entered into an agreement with his dad to take tuition support for art school with the understanding that this would be the last of his support.</p>
				<h3 className={classes['BlogPost__Pull-Quotes']}>The very first week I had a critique, the professor told me, ‘No one cares about your stuff.’”</h3>
				<p>It’s during this period that he came to develop his perspective on work and art. He talked a lot about confusion. Not knowing this, or not knowing that. Being in a foreign place and having your perspective on something shattered completely can do that to you.</p>
				<p>Struggling to find a starting point, Adrian mentioned, “I’m coming from a place where I didn’t know anything. It was like 1,000 doors opened, and I don't know which one to go through.”</p>
				<p>“I was doing stuff about family because I felt like it was a weighty topic,” he continued. Eventually he avoided this overt messaging. He felt uncomfortable forcing the issue. However, variations on this subject matter would reemerge later under more subtle tones.</p>
				<p>How did he get to his creative voice? In other interviews, he cites the artists who influenced him: Scheeler, Diebenkorn, Lautrec, Matisse, Albers. Even to the untrained eye, these influences are seen in Adrian’s work. Yet I got the sense that these artists were mainly providing stylistic support. His direction was shaped and developed out of necessity.</p>
				<p>When Adrian moved to LA five years ago, he didn’t know anyone outside of a single cousin and a friend who moved away shortly after. He lived in a studio off Crenshaw and Adams, which he described as “not the best neighborhood.” Introverted and insecure, he put his focus into painting.</p>
				<p>“I threw out my bed because I was like, ‘I need more space to paint. That's all that matters because I'm going to fucking die out here.’ I was sleeping in my closet in a sleeping bag for six months. I was like, ‘I need to paint. I need to paint.’”</p>
				<p>Except creativity wasn’t exploding in this confined space. Adrian explained that there were months in which painting was second to the mental hurdles of justifying his work amidst the agony of self-doubt. Searching for his creative identity, Adrian kept painting, pushing out more and more to the point where “walls started being covered. [He] had these tiny, narrow walk spaces because there's shit everywhere.” Compared to the chaotic environment, Adrian said, “I wanted to be like the surface of the canvas. It was always pristine and nice because I wanted to be like that.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper" src={ObservationsDesk} />
				<p>Reduce, reduce, reduce. “In order to simplify the things that were in front of me, I was spending less time doing detail.” His paintings are defined by clean lines, clean canvas, large blocks of color and devoid of clutter and the unnecessary. He yearned for cleanliness and order when life at the time seemed to lack it.</p>
				<p>We asked him why he chose to focus on images that were recollections of his past. Of all the things he could have painted, the recurring imagery was based on literal memory. We could imagine him in his studio constantly reverting back to his past. It felt instinctual. What was it about his past that made it worth discussing?</p>
				<p>“I’m very nostalgic. My favorite director is Wong Kar Wai because I think he has this romantic view of things long gone but very clearly influencing you in the present. I think for as long as I remember I have had this thing with how I grew up or how I'm a nobody in the art world. Everyone seems to know more than me, so it's always trying to prove that I have some sort of worth.</p>
				<p>“For me, in the present I have to figure out or navigate these past things where I was either too young, too immature or not knowledgeable enough to be able to handle them correctly. I worked through that in my own head. I’m still very stuck on those images, but I think nostalgia for things that aren't necessarily good is still present in my painting."</p>
				<h3 className={classes['BlogPost__Pull-Quotes']}>Everyone knows more than me, always trying to prove that I have some sort of worth.</h3>
				<p>Is he trying to rewrite his history? “In a way,” he said, “it’s a rewriting of how I see things.” If you could make sense of your past, would that allow you to move forward?</p>
				<p>Memory is fallible, malleable, even disorienting. By actively decluttering memories, Adrian condenses images down to a common denominator. The result is something more universal: colors, lines, and shapes that together become relatable to many.</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={ObservationsWall} />
				<p>One effect Adrian imposes on all his work is flattening 3D images into 2D, leaving no space for depth. To do this, he builds the image with geometric pieces that complement each other like a jigsaw puzzle. There's difficulty in distinguishing depth between shapes.</p>
				<p>“The distance between the vase to the arm of a chair -- they never overlap. The lamp doesn't overlap with the shoulder. The person inside the chair is contained within the exterior, which never happens in real life. Try to take a photo that looks like this painting. It would be impossible because there's too many angles of view that you have to composite together. You would never see just three legs of the chair like that and not see the bottom or the top of the chair.”</p>
				<p>How should we interpret this? Adrian said, “It's like taking this real-life thing and morphing it into, I don't want to say perfect, but there's a perfect way of representing these images. The lamp is perfect in a way, but in the way that it all comes together.”</p>
				<p>There's a desire for perfection, but a subtle acknowledgement that perfection is never achievable. Look at the lines and edges carefully. “There is imperfection. If you look close enough, you’ll see these lines aren't exactly straight” because they are drawn by hand.</p>
				<p>Through the process of flattening, he leaves every shape in direct competition for viewers to see. He even went so far as to remove faces from his work because they stood out too much. He believes this allows you to better appreciate the beauty in the everyday.</p>
				<p>When we asked Adrian about his inspirations over the years, he actually started with his father. It was a surprising comment given how he talked about their relationship earlier. “He’d never forced me to get A’s. It was just like, ‘You're not working hard enough. You’re not working hard enough. You’re never working hard enough. Why are you doing this if you can work harder on this?’” he recalled as he quoted his father.</p>
				<p>It’s this singular focus and intense work ethic that characterizes Adrian today. To survive in new environments without any direction, he said, “I didn't have a backup plan because my mentality was, ‘This is all on me, so I'm going to put everything I have into this. Nothing else matters.’” Giving up wasn’t really an option.</p>
				<p>Given the forum, we wondered if he ever cared to reveal his Asian-American identity through his art. The more we talked about his background, the more we felt like this identity was showing itself through his process, but he wasn’t discussing identity directly in his art. To this he told me about a time in college when an Asian-American professor asked him why he didn’t paint Asian people. Adrian responded that he just painted people: “In my head I was like, ‘How do I paint an Asian person in the style that I do without relying on very stereotypical choices like yellow toned skin or black hair.’”</p>
				<p>Nevertheless, Adrian was still trying to figure out how he could incorporate identity into his work. He tinkered with some ideas until a friend told him not to force this discussion in his work and that his identity would be built into his artistic choices. His work would reveal it. As an example, Adrian believed his work to be aligned to how people might think about Asian aesthetics – clean, minimal, lacking excess, balanced. These attributes weren’t intentional in that way yet developed over time. One could argue it wasn’t a coincidence.</p>
				<p>For Asian-Americans with immigrant parents, Adrian felt their parents’ experiences put pressure on them to prove their worth. The immigrant experience looked harder, more daunting. How do you chart a new path that reconciles a desire to create a supportive environment for your work with a respect for the past and the people who wrote it? Sometimes this acknowledgment of the past affects our ability to be completely sure of our own beliefs. Tradition questions evolution. Historical facts dampen the possibilities of the future. “You have your work that you’re set out to do,” Adrian said, “and just have confidence in that... But in doing that craziness, do it extremely hard, as hard as you can.”</p>
				<ImgWrapper extraClass="BlogPost__ImgWrapper--right" src={ObservationsBack} />
			</div>
		);
	}
}

export default Observations;