import React from 'react';
import { Link } from 'react-router-dom';

import ImgWrapper from '../../../components/UI/ImgWrapper/ImgWrapper';

import classes from './StoryLink.module.css';

const storyLink = (props) => (
	<div className={classes.StoryLinkWrapper}>
		<Link className={classes.StoryLink} to={props.path} >
			<ImgWrapper src={props.img_src} />
			<h3 className={classes.StoryLinkHeader}>{props.header}</h3>
			<p className={classes.StoryLinkDescription}>{props.description}</p>
		</Link>
	</div>
);

export default storyLink;