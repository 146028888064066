import React from 'react';
import { Link } from 'react-router-dom';

import SecondaryNav from "../SecondaryNav/SecondaryNav";

import classes from './PrimaryNav.module.css';
import { ReactComponent as Dotdotdot } from '../../../assets/images/Dotdotdot.svg';
import { ReactComponent as X } from '../../../assets/images/X.svg';

const primaryNav = (props) => {
	const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
	
	let button = <button className={classes.PrimaryNavBtn} onClick={props.showBtnClickedHandler}><Dotdotdot className={classes.PrimaryNavDotdotdot} /></button>;
	if (props.showSecondaryNav && viewportWidth <= 768) {
		button =
			<button className={classes.PrimaryNavBtn} onClick={props.closeBtnClickedHandler}><X className={classes.PrimaryNavX} /></button>
	}
	
	return (
		<nav className={classes.PrimaryNav}>
			<div className={classes.PrimaryNavContent}>
				<Link className={classes.PrimaryNavLink} to="/"><h1>Refractor</h1></Link>
				{button}
			</div>
			<SecondaryNav
				closeBtnClickedHandler={props.closeBtnClickedHandler}
				showSecondaryNav={props.showSecondaryNav} />
		</nav>
	);
};

export default primaryNav;