import React from 'react';

import { ReactComponent as ArrowUp } from '../../../assets/images/ArrowUp.svg';
import classes from './BtnToTop.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(classes);

const btnToTop = (props) => {
	let className = cx({
		BtnToTop: true,
		'BtnToTop--show': props.showScrollTopBtn
	});
	
	return (
		<button
			className={className}
			onClick={props.scrollToTop}
			type="button">
			<ArrowUp className={classes.BtnToTopIcon} />
		</button>
	);
};

export default btnToTop;